import { Container, MainTitle } from "@app/components";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util";
import { connect, GLGlobal, withRouter } from "gl-commonui";
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { StudentVerificationPage } from "./components/studentverification";
import { setBreadcrumbs } from '@app/states/resource';

interface VerifyStudentsPageProps extends RouteComponentProps<any> {
}
interface VerifyStudentsPageStates {
}

@withRouter
export class VerifyStudentsPage extends Component<VerifyStudentsPageProps, VerifyStudentsPageStates> {

    constructor(props) {
        super(props);
        setBreadcrumbs({
            verifystudents: {
                name: fmtMsg({ id: SchoolLocale.BreadTextVerifyStudents }),
                id: this.props.match.params.mergedStudentId,
            }
        });

        const pathArray = this.props.match.path.split("/");
        if (pathArray.indexOf("mergestudents") !== -1) {
            const params = this.props.match.params;
            setBreadcrumbs({
                mergestudents: {
                    name: fmtMsg({ id: SchoolLocale.BreadTextMergeStudents }),
                    id: params.mergedStudentId,
                    extraInfo: {
                        classId: params.mergedClassId
                    }
                }
            });
        }
    }

    render() {
        return (
            <Container fullWidth>
                <MainTitle plain={fmtMsg({ id: GSAdminLocale.RegionManageStudentMergeVerification })} />
                <StudentVerificationPage {...this.props} />
            </Container>
        );
    }
}
